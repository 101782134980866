import React from "react";
import { navigate } from "gatsby";

import Seo from "layouts/Seo";
import Button from "elements/Button";

import BrokenRobotSVG from "/static/images/svgs/broken-robot.svg";

const NotFoundPage = () => {
  return (
    <div className="page-not-found mt-6 has-text-centered">
      <Seo title="Page Not Found" />
      <BrokenRobotSVG height={250} width={250} />
      <h3 className="title is-3 mb-1">Page Not Found</h3>
      <p className="mb-5">
        Oof, this is awkward. It looks like the page you were trying to access
        does not exists.
      </p>
      <Button
        color="primary"
        handleClick={() => {
          navigate("/");
        }}
      >
        Go Back to Home
      </Button>
    </div>
  );
};

export default NotFoundPage;
